import { Container, Stack, Text, Button } from '@carvertical/ui';
import { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { type FilePondFile, FileStatus } from 'filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import { renderToString } from 'react-dom/server';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useTranslation } from 'next-i18next';
import { useSteps } from 'hooks/useSteps';
import { useDrawer } from 'hooks/useDrawer';
import { Step } from 'types/step';
import { usePhotos } from 'hooks/usePhotos';
import { Section } from 'components/common/Section';
import { FilePondLabel } from './FilePondLabel';
import { IMAGE_MIN_SIZE, MAX_FILES } from './constants';
import styles from './ImageSelectSection.module.scss';

type FilePondError = {
  body?: string;
};

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize,
);

const SUPPORTED_FILE_TYPES = ['image/png', 'image/jpeg'];

const NOTIFICATION_DURATION = 6000;

const ImageSelectSection = () => {
  const { setStep } = useSteps();
  const [warning, setWarning] = useState<FilePondError | null>(null);
  const { photos, setPhotos } = usePhotos();
  const { setSetting } = useDrawer();
  const { t } = useTranslation(['imageSelectSection', 'common', 'imageErrors']);

  const handleAddedFiles = (files: FilePondFile[]) => {
    const validPhotos = files.filter((file) =>
      [FileStatus.IDLE, FileStatus.INIT].includes(file.status),
    );
    setPhotos(validPhotos);
  };

  const handleProceed = () => {
    setStep(Step.Configuration);
    setSetting({ available: true });
  };

  return (
    <Section color="colorLightBlue100">
      <Container>
        <Stack crossAxisAlign="stretch" gap={4}>
          <Text as="h1" variant="lStrong" align="center">
            {t('imageSelectSection:title')}
          </Text>

          <div className={styles.wrapper}>
            <FilePond
              files={photos.map((photo) => photo.file)}
              allowMultiple
              imageValidateSizeMinWidth={IMAGE_MIN_SIZE}
              imageValidateSizeMinHeight={IMAGE_MIN_SIZE}
              maxFiles={MAX_FILES}
              name="files"
              labelIdle={renderToString(
                <FilePondLabel
                  title={t('imageSelectSection:uploadPhotos', {
                    size: IMAGE_MIN_SIZE,
                  })}
                  subtitle={t('imageSelectSection:dropFiles', { maxFiles: MAX_FILES })}
                />,
              )}
              acceptedFileTypes={SUPPORTED_FILE_TYPES}
              onupdatefiles={handleAddedFiles}
              onwarning={setWarning}
              // @ts-expect-error TS(2769)
              credits={null}
              styleItemPanelAspectRatio="1"
              styleButtonRemoveItemPosition="right"
              iconRemove={
                '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path stroke="currentColor" d="M7 7l18 18M25 7L7 25"/></svg>'
              }
              imageValidateSizeLabelExpectedMinSize={t('imageErrors:minimumSize', {
                size: IMAGE_MIN_SIZE,
              })}
              imageValidateSizeLabelImageSizeTooSmall={t('imageErrors:imageTooSmall')}
            />

            {!!photos.length && (
              <div className={styles.buttonWrapper}>
                <Button variant="blue" onClick={handleProceed} fullWidth>
                  {t('common:proceed')}
                </Button>
              </div>
            )}
          </div>
        </Stack>

        <Snackbar
          open={!!warning}
          autoHideDuration={NOTIFICATION_DURATION}
          onClose={() => setWarning(null)}
        >
          <Alert severity="warning" variant="filled" elevation={6}>
            {t('imageErrors:warning')}: {warning?.body}
          </Alert>
        </Snackbar>
      </Container>
    </Section>
  );
};

export { ImageSelectSection };
