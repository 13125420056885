import type { MarketId } from 'types/settings';

const MARKET_LANGUAGE: Record<MarketId, string> = {
  usa: 'English',
  lithuania: 'Lietuvių',
  latvia: 'Latviešu',
  poland: 'Polski',
  romania: 'Română',
  hungary: 'Magyar',
  france: 'Français',
  czechia: 'Český',
  croatia: 'Hrvatski',
  bulgaria: 'Български',
  italy: 'Italiano',
  estonia: 'Eesti',
};

export { MARKET_LANGUAGE };
