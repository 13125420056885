import { clsx } from 'clsx';
import { theme } from '@carvertical/ui';
import type { ThemeColor } from 'types/color';
import styles from './Section.module.scss';

type SectionProps = {
  className?: string;
  color?: ThemeColor | 'transparent';
};

const Section = ({
  children,
  className,
  color = 'colorWhite',
}: React.PropsWithChildren<SectionProps>) => (
  <section
    className={clsx(styles.root, className)}
    style={{
      '--backgroundColor': color === 'transparent' ? 'transparent' : theme[color],
    }}
  >
    {children}
  </section>
);

export { Section };
