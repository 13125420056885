import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Slider,
} from '@material-ui/core';
import Image from 'next/image';
import { Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { IMAGES, DEFAULT_WATERMARK_OFFSET, MAX_WATERMARK_OFFSET } from 'constants/watermark';
import { MARKET_LANGUAGE } from 'constants/language';
import { MARKETS } from 'markets';
import theme from 'theme';
import { useSettings } from 'hooks/useSettings';
import { WatermarkPosition } from 'types/settings';

const useStyles = makeStyles({
  highlighted: {
    color: theme.palette.primary.main,
  },
});

const POSITION_OPTIONS = [
  {
    value: WatermarkPosition.TopLeft,
    labelKey: 'position.topLeft',
  },
  {
    value: WatermarkPosition.TopRight,
    labelKey: 'position.topRight',
  },
  {
    value: WatermarkPosition.BottomLeft,
    labelKey: 'position.bottomLeft',
  },
  {
    value: WatermarkPosition.BottomRight,
    labelKey: 'position.bottomRight',
  },
];

const Settings = () => {
  const { position, watermark, market, setSetting } = useSettings();
  const classes = useStyles();
  const { t } = useTranslation('settingsDrawer');

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {t('settingsDrawer:title')}
      </Typography>
      <FormControl fullWidth margin="normal">
        <FormControl>
          <FormLabel className={classes.highlighted}>{t('chooseWatermarkLanguage')}</FormLabel>
          <Select
            value={market}
            onChange={(event) =>
              setSetting({
                market: event.target.value,
                watermark: IMAGES[event.target.value],
              })
            }
          >
            {Object.keys(MARKETS).map((marketId) => (
              <MenuItem key={marketId} value={marketId}>
                {MARKET_LANGUAGE[marketId]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Stack>
          <FormLabel>{t('watermarkImage')}</FormLabel>
          <Image src={watermark.src} alt={watermark.title} width={266} height={100} />
        </Stack>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <FormLabel className={classes.highlighted}>{t('chooseWatermarkPlacing')}</FormLabel>
        <RadioGroup
          aria-label={t('watermarkPosition')}
          name="position"
          value={position}
          onChange={(event) => setSetting({ position: event.target.value })}
        >
          {POSITION_OPTIONS.map(({ value, labelKey }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio color="primary" id={`radio-${value}`} />}
              label={t(labelKey)}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Stack>
          <FormLabel>{t('watermarkOffset')}</FormLabel>
          <Slider
            min={0}
            max={MAX_WATERMARK_OFFSET}
            step={1}
            defaultValue={DEFAULT_WATERMARK_OFFSET}
            valueLabelDisplay="auto"
            onChange={(event, value) => {
              setSetting({
                offsetScale: value,
              });
            }}
          />
        </Stack>
      </FormControl>
    </div>
  );
};

export { Settings };
