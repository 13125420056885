import { Icon, Stack, Text } from '@carvertical/ui';
import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';
import { useSteps } from 'hooks/useSteps';
import { useDrawer } from 'hooks/useDrawer';
import { useSettings } from 'hooks/useSettings';
import { usePhotos } from 'hooks/usePhotos';
import { Step } from 'types/step';
import type { TFuncStringKey } from 'types/i18n';
import styles from './Steps.module.scss';

type StepsProps = {
  className?: string;
};

const STEP_LABEL: Record<Step, TFuncStringKey> = {
  [Step.Introduction]: 'steps.uploadImages',
  [Step.Configuration]: 'steps.configuration',
};

const Steps = ({ className }: StepsProps) => {
  const { step: currentStep, resetSteps } = useSteps();
  const { resetSettings: resetDrawerSettings } = useDrawer();
  const { resetSettings } = useSettings();
  const { photos } = usePhotos();
  const { t } = useTranslation();

  const goToIntroduction = () => {
    resetSettings();
    resetSteps();
    resetDrawerSettings();
  };

  return (
    <Stack
      type="horizontal"
      gap={{
        mobileUp: 2,
        tabletPortraitUp: 8,
      }}
      mainAxisAlign="center"
      className={clsx(styles.root, className)}
    >
      {Object.values(Step).map((step, index) => {
        const active = currentStep === step;
        const introductionStep = step === Step.Introduction;
        const introductionActionEnabled = introductionStep && !active;
        const firstStepCompleted = !!photos.length;

        return (
          <Stack
            type="horizontal"
            crossAxisAlign="center"
            gap={{ mobileUp: 1, tabletLandscapeUp: 2 }}
            key={step}
          >
            <button
              type="button"
              onClick={goToIntroduction}
              className={clsx(styles.number, active && styles.active)}
              disabled={!introductionActionEnabled}
            >
              {firstStepCompleted && introductionStep ? (
                <Icon name="check-thick" size="m" />
              ) : (
                <Text variant="mStrong" textColor="inherited">
                  {index + 1}
                </Text>
              )}
            </button>

            <Text variant="xs" textColor="inherited">
              {t(STEP_LABEL[step])}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

export { Steps };
