import { Container, Stack } from '@carvertical/ui';
import Image from 'next/image';
import { Steps } from './Steps';
import styles from './Header.module.scss';

const ALT_TEXT = 'carVertical';

const APP_NAME = 'Trust Badge';

const Header = () => (
  <header className={styles.root}>
    <Container className={styles.container}>
      <Stack
        type="horizontal"
        crossAxisAlign="center"
        mainAxisAlign={{ mobileUp: 'center', tabletLandscapeUp: 'spaceBetween' }}
      >
        <Stack type="horizontal" crossAxisAlign="flexEnd">
          <Image src="/logo.svg" alt={ALT_TEXT} className={styles.logo} width={231} height={32} />
          <span className={styles.label}>{APP_NAME}</span>
        </Stack>

        <Steps className="hideUntilTabletLandscape" />
      </Stack>
    </Container>
  </header>
);

export { Header };
