import { Toolbar, Drawer as MuiDrawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import type { DrawerProps } from '@material-ui/core/Drawer';
import { clsx } from 'clsx';
import { useDrawer } from 'hooks/useDrawer';
import styles from './Drawer.module.scss';

const Drawer = ({ children }: React.PropsWithChildren) => {
  const { available, opened, setSetting } = useDrawer();

  if (!available) {
    return null;
  }

  const commonProps: DrawerProps = {
    anchor: 'right',
    classes: {
      paper: styles.drawerPaper,
    },
  };

  const closeDrawer = () => setSetting({ opened: false });

  return (
    <>
      <MuiDrawer
        {...commonProps}
        className={clsx(styles.root, 'hideFromTabletLandscape')}
        variant="temporary"
        open={opened}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        data-testid="Drawer-mobileDrawer"
      >
        <>
          <Toolbar variant="dense" className={styles.close} disableGutters>
            <IconButton edge="end" data-testid="Drawer-closeButton" onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          </Toolbar>

          {children}
        </>
      </MuiDrawer>

      <MuiDrawer
        {...commonProps}
        variant="permanent"
        data-testid="Drawer-desktopDrawer"
        className={clsx(styles.root, 'hideUntilTabletLandscape')}
      >
        <>
          <Toolbar />
          {children}
        </>
      </MuiDrawer>
    </>
  );
};

export { Drawer };
