import { Icon, Text, Stack } from '@carvertical/ui';
import styles from './FilePondLabel.module.scss';

type FilePondLabelProps = {
  title: string;
  subtitle: React.ReactNode;
};

const FilePondLabel = ({ title, subtitle }: FilePondLabelProps) => (
  <Stack
    gap={{ mobileUp: 2, tabletLandscapeUp: 3 }}
    type={{ mobileUp: 'vertical', tabletLandscapeUp: 'horizontal' }}
    crossAxisAlign="center"
  >
    <Stack crossAxisAlign="center" mainAxisAlign="center" className={styles.icon}>
      <Icon name="add-image" size="l" />
    </Stack>

    <Stack gap={0.5} crossAxisAlign="center" className={styles.text}>
      <Text variant="lStrong" textColor="highlighted">
        {title}
      </Text>

      <Text variant="s" textColor="highlighted">
        {subtitle}
      </Text>
    </Stack>
  </Stack>
);

export { FilePondLabel };
