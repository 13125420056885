import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Layer, Stage, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useTranslation } from 'next-i18next';
import { MAX_WATERMARK_OFFSET } from 'constants/watermark';
import { useSettings } from 'hooks/useSettings';
import { WatermarkPosition } from 'types/settings';
import styles from './WatermarkImage.module.scss';

const WIDTH_SCALE_L = 0.4;
const WIDTH_SCALE_M = 0.3;
const WIDTH_SCALE_S = 0.25;

const IMAGE_S_BREAKPOINT = 600;
const IMAGE_M_BREAKPOINT = 900;

const DEFAULT_COORDINATES = {
  x: 0,
  y: 0,
};

const DEFAULT_IMAGE_SETTINGS = {
  src: null,
  width: 0,
  height: 0,
};

const calcBaseOffset = ({ offset: { baseStep, scale } }) => baseStep * scale;

const calcInverseWidthOffset = (props) =>
  props.source.width - props.watermark.width - calcBaseOffset(props);

const calcInverseHeightOffset = (props) =>
  props.source.height - props.watermark.height - calcBaseOffset(props);

const WATERMARK_POSITION_MAP = {
  [WatermarkPosition.TopLeft]: {
    x: calcBaseOffset,
    y: calcBaseOffset,
  },
  [WatermarkPosition.TopRight]: {
    x: calcInverseWidthOffset,
    y: calcBaseOffset,
  },
  [WatermarkPosition.BottomLeft]: {
    x: calcBaseOffset,
    y: calcInverseHeightOffset,
  },
  [WatermarkPosition.BottomRight]: {
    x: calcInverseWidthOffset,
    y: calcInverseHeightOffset,
  },
};

const calcWatermarkScale = (sourceWidth) => {
  if (sourceWidth < IMAGE_S_BREAKPOINT) {
    return WIDTH_SCALE_L;
  }

  if (sourceWidth < IMAGE_M_BREAKPOINT) {
    return WIDTH_SCALE_M;
  }

  return WIDTH_SCALE_S;
};

const useResources = (file) => {
  const [watermark, setWatermark] = useState(DEFAULT_IMAGE_SETTINGS);
  const [source, setSource] = useState(DEFAULT_IMAGE_SETTINGS);
  const {
    watermark: { src },
  } = useSettings();

  const imageSrc = useMemo(() => URL.createObjectURL(file), [file]);

  const [watermarkImage, watermarkImageStatus] = useImage(src);
  const [sourceImage, sourceImageStatus] = useImage(imageSrc);

  useEffect(() => {
    if (watermarkImageStatus === 'loaded' && sourceImageStatus === 'loaded') {
      const widthScale = calcWatermarkScale(sourceImage.width);

      setSource({
        src: sourceImage,
        width: sourceImage.width,
        height: sourceImage.height,
      });

      const watermarkWidth = Math.round(sourceImage.width * widthScale);
      const ratio = watermarkWidth / watermarkImage.width;
      const watermarkHeight = Math.round(watermarkImage.height * ratio);

      setWatermark({
        src: watermarkImage,
        width: watermarkWidth,
        height: watermarkHeight,
      });
    }
  }, [watermarkImageStatus, sourceImageStatus, sourceImage, watermarkImage]);

  return {
    watermark,
    source,
  };
};

const useFinalImage = ({ watermark, source }) => {
  const [drawn, setDrawn] = useState(false);
  const { offsetScale, position } = useSettings();
  const [watermarkCoordinates, setWatermarkCoordinates] = useState(DEFAULT_COORDINATES);

  useEffect(() => {
    setDrawn(false);

    if (watermark.src && source.src) {
      const watermarkWidth = watermark.width;
      const watermarkHeight = watermark.height;
      const sourceWidth = source.width;
      const sourceHeight = source.height;

      const base =
        sourceWidth < sourceHeight ? sourceWidth - watermarkWidth : sourceHeight - watermarkHeight;

      const offsetBaseStep = base / 2 / MAX_WATERMARK_OFFSET;

      const finalImageConfig = {
        position,
        offset: {
          baseStep: offsetBaseStep,
          scale: offsetScale,
        },
        watermark: {
          width: watermarkWidth,
          height: watermarkHeight,
        },
        source: {
          width: sourceWidth,
          height: sourceHeight,
        },
      };

      setWatermarkCoordinates({
        x: WATERMARK_POSITION_MAP[position].x(finalImageConfig),
        y: WATERMARK_POSITION_MAP[position].y(finalImageConfig),
      });

      setDrawn(true);
    }
  }, [offsetScale, position, watermark, source]);

  return {
    drawn,
    watermarkCoordinates,
  };
};

const WatermarkImage = ({ photo: { id, filename, file }, onOpen, onDrawImage }) => {
  const { t } = useTranslation();
  const finalImageCanvasRef = useRef(null);
  const previewImageCanvasRef = useRef(null);
  const rootRef = useRef(null);
  const { position } = useSettings();
  const { watermark, source } = useResources(file);
  const { drawn, watermarkCoordinates } = useFinalImage({ watermark, source });

  useEffect(() => {
    if (!drawn) {
      return;
    }

    onDrawImage({
      src: finalImageCanvasRef.current,
      w: source.width,
      h: source.height,
      pid: id,
      filename,
      mimeType: file.type,
    });
  }, [drawn, filename, id, onDrawImage, source, position, watermark, file]);

  useEffect(() => {
    const fitStageIntoParentContainer = () => {
      const container = rootRef.current;
      const stage = previewImageCanvasRef.current.getStage();

      const containerWidth = container.offsetWidth;

      const scale = containerWidth / source.width;

      stage.width(source.width * scale);
      stage.height(source.height * scale);
      stage.scale({ x: scale, y: scale });
    };

    window.addEventListener('resize', fitStageIntoParentContainer);

    if (drawn) {
      fitStageIntoParentContainer();
    }

    return () => {
      window.removeEventListener('resize', fitStageIntoParentContainer);
    };
  }, [drawn, source]);

  return (
    <div
      ref={rootRef}
      className={styles.root}
      onClick={onOpen}
      role="button"
      tabIndex={0}
      aria-label={t('openWatermarkedImageAlt')}
      onKeyPress={(event) => {
        if (event.code === 'Enter') {
          onOpen();
        }
      }}
    >
      <Stage ref={previewImageCanvasRef} listening={false}>
        <Layer>
          <KonvaImage image={source.src} />
          <KonvaImage
            image={watermark.src}
            x={watermarkCoordinates.x}
            y={watermarkCoordinates.y}
            height={watermark.height}
            width={watermark.width}
          />
        </Layer>
      </Stage>

      <Stage
        ref={finalImageCanvasRef}
        width={source.width}
        height={source.height}
        className={styles.hiddenCanvas}
      >
        <Layer>
          <KonvaImage image={source.src} />
          <KonvaImage
            image={watermark.src}
            x={watermarkCoordinates.x}
            y={watermarkCoordinates.y}
            height={watermark.height}
            width={watermark.width}
          />
        </Layer>
      </Stage>
    </div>
  );
};

WatermarkImage.propTypes = {
  photo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    file: PropTypes.shape().isRequired,
  }).isRequired,
  onOpen: PropTypes.func.isRequired,
  onDrawImage: PropTypes.func.isRequired,
};

export { WatermarkImage };
