import { Container, Text, GridItem, Grid, Heading, Stack, Icon } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { Section } from 'components/common/Section';
import { AdvertCard } from './AdvertCard';
import styles from './IntroductionSection.module.scss';

const FEATURE_LIST = ['transparency', 'customerSatisfaction', 'fasterSales'] as const;

const IntroductionSection = () => {
  const { t } = useTranslation('introductionSection');

  return (
    <Section color="colorLightBlue200">
      <Container>
        <Grid rowGap={{ mobileUp: 8, tabletLandscapeUp: 5 }}>
          <GridItem
            span={{ mobileUp: 12, tabletLandscapeUp: 5 }}
            as={Stack}
            // @ts-expect-error TS(2322)
            mainAxisAlign="center"
          >
            <Stack gap={3}>
              <Heading as="h2" variant="l">
                {t('title')}
              </Heading>

              <Stack gap={3}>
                <Stack gap={2}>
                  <Text variant="mExtra">{t('businessHelp')}</Text>

                  <Stack as="ul" className="unstyledList" gap={2}>
                    {FEATURE_LIST.map((feature) => (
                      <Stack
                        gap={0.5}
                        as="li"
                        key={feature}
                        type="horizontal"
                        crossAxisAlign="center"
                      >
                        <Icon name="check-thick" size="m" className={styles.icon} />
                        <Text variant="sStrong">{t(`features.${feature}`)}</Text>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>

                <Trans parent={Text} i18nKey="caseStudy" t={t} variant="s">
                  <strong>Case study:</strong> We did a survey with one of the biggest auto dealers
                  in Eastern Europe, “Autojuta”, by releasing 2 cars of the same model on sale
                  online. One car ad was plain, while the other had a carVertical Trust badge. It
                  turned out that people clicked 21% more on the latter.
                </Trans>
              </Stack>
            </Stack>
          </GridItem>

          <GridItem
            start={{ mobileUp: 1, tabletLandscapeUp: 7 }}
            span={{ mobileUp: 12, tabletLandscapeUp: 6 }}
            as={Stack}
            // @ts-expect-error TS(2322)
            mainAxisAlign={{ mobileUp: 'center', tabletLandscapeUp: 'flexEnd' }}
            type="horizontal"
            gap={2}
          >
            <AdvertCard className="hideUntilTabletLandscape" />
            <AdvertCard highlighted />
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

export { IntroductionSection };
