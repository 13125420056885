import { clsx } from 'clsx';
import Image from 'next/image';
import { Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { IMAGES } from 'constants/watermark';
import { useMarket } from 'hooks/useMarket';
import { FloatingBadge } from './FloatingBadge';
import audiUrl from './assets/audi.jpg';
import advert1Url from './assets/advert-1.jpg';
import advert2Url from './assets/advert-2.jpg';
import advert3Url from './assets/advert-3.jpg';
import styles from './AdvertCard.module.scss';

type AdvertCardProps = {
  highlighted?: boolean;
  className?: string;
};

const SELLING_PERCENTAGE = 21;

const ADVERTS = [
  {
    title: 'Audi Q8',
    price: '53 900 €',
    image: advert1Url,
  },
  {
    title: 'Audi Q8',
    price: '65 990 €',
    image: advert2Url,
    watermarked: true,
  },
  {
    title: 'Audi Q8',
    price: '65 800 €',
    image: advert3Url,
  },
];

const AdvertCard = ({ highlighted = false, className }: AdvertCardProps) => {
  const { market } = useMarket();
  const { t } = useTranslation('introductionSection');

  const watermarkImage = market ? IMAGES[market] : IMAGES.usa;

  return (
    <Stack as="figure" crossAxisAlign="stretch" gap={0} className={clsx(styles.root, className)}>
      <figcaption className={styles.caption}>
        <FloatingBadge
          highlighted={highlighted}
          text={highlighted ? t('withTrustBadgeLabel') : t('withoutTrustBadgeLabel')}
        />
      </figcaption>

      <div className={clsx(styles.wrapper, highlighted && styles.highlighted)}>
        <div className={styles.mainPhoto}>
          <div className={styles.photoWrapper}>
            <Image
              src={audiUrl}
              alt=""
              width={232}
              height={185}
              quality={100}
              className={styles.audi}
            />

            {highlighted && (
              <Image
                className={styles.watermarkMain}
                src={watermarkImage.src}
                alt={watermarkImage.title}
                width={72}
                height={27}
              />
            )}
          </div>
        </div>

        <div className={styles.content}>
          <ul className={styles.list}>
            {ADVERTS.map((advert) => (
              <Stack
                as="li"
                type="horizontal"
                key={`${advert.title}-${advert.price}`}
                className={styles.item}
              >
                <div className={styles.photoWrapper}>
                  <Image
                    src={advert.image}
                    alt=""
                    width={100}
                    height={77}
                    quality={100}
                    className={styles.image}
                  />

                  {highlighted && advert.watermarked && (
                    <Image
                      className={styles.watermarkMini}
                      src={watermarkImage.src}
                      alt={watermarkImage.title}
                      width={39}
                      height={15}
                    />
                  )}
                </div>

                <Stack className={styles.text}>
                  <Text variant="xxsStrong">{advert.title}</Text>

                  <div className={clsx(styles.placeholder, styles.widePlaceholder)} />

                  <div className={clsx(styles.placeholder, styles.narrowPlaceholder)} />

                  <Text variant="xxsStrong">{advert.price}</Text>
                </Stack>
              </Stack>
            ))}
          </ul>

          {highlighted && (
            <Stack gap={2} crossAxisAlign="center" type="horizontal" className={styles.footer}>
              <Heading variant="l" as="p" textColor="light">
                +{SELLING_PERCENTAGE}%
              </Heading>

              <Text variant="xxxs" textColor="light">
                {t('moreLikelyToClick')}
              </Text>
            </Stack>
          )}
        </div>
      </div>
    </Stack>
  );
};

export { AdvertCard };
