import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';
import { useTranslation } from 'next-i18next';
import styles from './Gallery.module.scss';

const Gallery = ({ photos, index, onClose }) => {
  const rootRef = useRef();
  const { t } = useTranslation('imageGallery');

  useEffect(() => {
    const options = {
      index,
      history: false,
      showHideOpacity: true,
      getThumbBoundsFn: (key) => {
        const thumbnail = document.getElementById(photos[key].pid);
        if (!thumbnail) {
          return undefined;
        }
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        const { top, left, width } = thumbnail.getBoundingClientRect();
        return { x: left, y: top + pageYScroll, w: width };
      },
      captionEl: false,
      fullscreenEl: false,
      shareEl: false,
    };

    const photoSwipe = new PhotoSwipe(
      rootRef.current,
      PhotoSwipeUI,
      photos.map((photo) => ({
        ...photo,
        src: photo.src.toDataURL({ mimeType: photo.mimeType }),
      })),
      options,
    );

    photoSwipe.init();

    photoSwipe.listen('destroy', () => {
      onClose();
    });
  }, [onClose, photos, index]);

  return (
    <div
      ref={rootRef}
      className={clsx('pswp', styles.pswp)}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <div className="pswp__bg" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />
            <button className="pswp__button pswp__button--close" type="button" title={t('close')} />
            <button className="pswp__button pswp__button--share" type="button" title={t('share')} />
            <button
              className="pswp__button pswp__button--fs"
              type="button"
              title={t('toggleFullscreen')}
            />
            <button
              className="pswp__button pswp__button--zoom"
              type="button"
              title={t('zoomInOut')}
            />
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <button
            className={clsx('pswp__button', 'pswp__button--arrow--left', styles.arrowLeft)}
            type="button"
            title={t('previousImage')}
          />
          <button
            className={clsx('pswp__button', 'pswp__button--arrow--right', styles.arrowRight)}
            type="button"
            title={t('nextImage')}
          />
          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
      {/* eslint-enable jsx-a11y/control-has-associated-label */}
    </div>
  );
};

Gallery.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  index: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

Gallery.defaultProps = {
  index: 0,
};

export { Gallery };
