import { useSteps } from 'hooks/useSteps';
import { Step } from 'types/step';
import { Page } from 'components/common/Page';
import { Settings } from 'components/common/Settings';
import { WatermarkPreviewSection } from 'components/sections/WatermarkPreviewSection';
import { IntroductionSection } from 'components/sections/IntroductionSection';
import { ImageSelectSection } from 'components/sections/ImageSelectSection';

const HomePage = () => {
  const { step } = useSteps();

  return (
    <Page drawerChildren={<Settings />}>
      {step === Step.Introduction && (
        <>
          <ImageSelectSection />
          <IntroductionSection />
        </>
      )}
      {step === Step.Configuration && <WatermarkPreviewSection />}
    </Page>
  );
};

export { HomePage };
