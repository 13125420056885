import { Icon, Text } from '@carvertical/ui';
import { clsx } from 'clsx';
import styles from './FloatingBadge.module.scss';

type FloatingBadgeProps = {
  text: string;
  highlighted?: boolean;
};

const FloatingBadge = ({ highlighted, text }: FloatingBadgeProps) => (
  <div className={styles.root}>
    <div className={clsx(styles.wrapper, highlighted && styles.highlighted)}>
      <div className={styles.textWrapper}>
        <Text variant="xxxs">{text}</Text>
      </div>

      {highlighted && <Icon name="check-rounded" size="s" className={styles.icon} />}
    </div>
  </div>
);

export { FloatingBadge };
