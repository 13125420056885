import { useCallback, useState } from 'react';
import { Fab, Grid } from '@material-ui/core';
import { Container, Button, Stack } from '@carvertical/ui';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import imageCompression from 'browser-image-compression';
import TuneIcon from '@material-ui/icons/Tune';
import { useTranslation } from 'next-i18next';
import { getDateTimeString } from 'utils/date';
import { useDrawer } from 'hooks/useDrawer';
import { usePhotos } from 'hooks/usePhotos';
import { useSteps } from 'hooks/useSteps';
import { useSettings } from 'hooks/useSettings';
import { Section } from 'components/common/Section';
import { WatermarkImage } from './WatermarkImage';
import { Gallery } from './Gallery';
import styles from './WatermarkPreviewSection.module.scss';

const WatermarkPreviewSection = () => {
  const { setPhotos, photos } = usePhotos();
  const { resetSettings: resetDrawerSettings, setSetting } = useDrawer();
  const { resetSteps } = useSteps();
  const { resetSettings } = useSettings();
  const [downloading, setDownloading] = useState(false);
  const [galleryOpenedAtIndex, setGalleryOpenedAtIndex] = useState();
  const [galleryItems, setGalleryItems] = useState([]);
  const { t } = useTranslation();

  const openGallery = (id) => {
    const index = galleryItems.findIndex((item) => item.pid === id);
    setGalleryOpenedAtIndex(index);
  };

  const closeGallery = () => {
    setGalleryOpenedAtIndex();
  };

  const download = async () => {
    setDownloading(true);
    const zip = new JSZip();
    const folder = zip.folder('collection');

    await galleryItems.reduce(async (lastPromise, { src, filename, mimeType }) => {
      await lastPromise;

      const dataUrl = src.toDataURL({ mimeType });

      const file = await imageCompression.getFilefromDataUrl(dataUrl, filename);

      folder.file(filename, file);
    }, Promise.resolve());

    try {
      const content = await folder.generateAsync({ type: 'blob' });
      saveAs(content, `watermarked-${getDateTimeString()}.zip`);
    } finally {
      setDownloading(false);
    }
  };

  const handleDrawImage = useCallback(
    (drawnImage) => {
      const foundIndex = galleryItems.findIndex((item) => item.pid === drawnImage.pid);

      if (foundIndex > -1) {
        galleryItems[foundIndex] = drawnImage;
      } else {
        galleryItems.push(drawnImage);
      }

      setGalleryItems(galleryItems);
    },
    [galleryItems],
  );

  const reset = () => {
    setPhotos([]);
    resetSettings();
    resetSteps();
    resetDrawerSettings();
  };

  return (
    <Section color="transparent">
      <Container>
        <Grid container justifyContent="center">
          <div className={styles.masonry}>
            {photos.map((photo) => (
              <WatermarkImage
                key={photo.id}
                photo={photo}
                onOpen={() => openGallery(photo.id)}
                onDrawImage={handleDrawImage}
              />
            ))}
          </div>
        </Grid>

        <Grid container justifyContent="center">
          <Stack type="horizontal">
            <Button
              size="l"
              variant="blue"
              leadingIcon="download"
              loading={downloading}
              onClick={() => {
                download(photos);
              }}
            >
              {t('download')}
            </Button>
            <Button size="l" variant="outlined" leadingIcon="close" onClick={reset}>
              {t('reset')}
            </Button>
          </Stack>
        </Grid>

        {galleryOpenedAtIndex > -1 && (
          <Gallery index={galleryOpenedAtIndex} photos={galleryItems} onClose={closeGallery} />
        )}

        <Fab
          color="primary"
          aria-label="add"
          className={styles.fab}
          onClick={() => setSetting({ opened: true })}
        >
          <TuneIcon />
        </Fab>
      </Container>
    </Section>
  );
};

export { WatermarkPreviewSection };
