import { Container } from '@carvertical/ui';
import { Drawer } from 'components/common/Drawer';
import { Header } from './Header';
import { Steps } from './Steps';
import styles from './Page.module.scss';

type PageProps = {
  drawerChildren?: React.ReactNode;
};

const Page = ({ children, drawerChildren }: React.PropsWithChildren<PageProps>) => (
  <>
    <Header />

    <div className={styles.mobileSteps}>
      <Container>
        <Steps />
      </Container>
    </div>

    <div className={styles.wrapper}>
      <main className={styles.main}>{children}</main>

      <Drawer>{drawerChildren}</Drawer>
    </div>
  </>
);

export { Page };
